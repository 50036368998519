import { EProjectMemberRole, EUserStatus } from '../enums';
import { TProject, TWorkspace } from '../types';
import { IUser } from './user.interface';

export interface IProjectMember {
  id: string;
  role: EProjectMemberRole | string;
  status: EUserStatus | string;
  invitedAt: string;
  invitedBy: IUser;
  project: TProject;
  user: IUser;
  workspaces: TWorkspace[];
}
