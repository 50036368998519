import React, { Fragment } from 'react';

export default function Header() {
  const projectName = localStorage.getItem('projectName');

  return (
    <div className=" sticky mt-10  ml-10">
      <span className="text-xl">Mes Project / </span>
      <span className="text-xl font-bold">{projectName}</span>
    </div>
  );
}
