import axios from 'axios';
import { environment } from '../environments/environment';
import { auth } from './firebase';

const baseUrl = `${environment.api_host}`;

// Create a single Axios instance to use across the app
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('tri') || ''}`,
  },
});

// Request Interceptor: Add Authorization header
axiosInstance.interceptors.request.use(
  async (config) => {
    // Ensure headers exist
    config.headers = config.headers || {};

    // Update token if user is authenticated
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to headers
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor: Handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const token = await currentUser.getIdToken(true); // Force refresh the token
        localStorage.setItem('tri', token); // Update local storage with new token
        error.config.headers['Authorization'] = `Bearer ${token}`; // Update the original request with the new token
        return axiosInstance(error.config); // Retry the request with new token
      }
    }
    return Promise.reject(error);
  }
);

// Exported API methods using the single axios instance
export const api = {
  get: (url = '', params?: object) =>
    axiosInstance.get<unknown>(url, { params }), 
  post: <T>(url = '', data: T, params?: object) =>
    axiosInstance.post<T>(url, data, { params }), 
  patch: <T>(url = '', data: T, params?: object) =>
    axiosInstance.patch<T>(url, data, { params }), 
  put: <T>(url = '', data: T, params?: object) => 
  axiosInstance.put<T>(url, data, { params }), 
  delete: (url = '', params?: object, headers?: object) => 
    axiosInstance.delete(url, { params, headers }),
};
