import { EUserRoles } from '../enums';
import {
  TAttachement,
  TCollaboratorProfile,
  TProjectMember,
  TReportProfile,
} from '../types';

export type IUser = {
  uuid: string;
  externalId: string;
  email: string;
  fullName: string;
  lastName: string;
  phoneMobile: string;
  role: EUserRoles | string;
  phoneOffice?: string;
  faxNumber?: string;
  postalCode?: string;
  city?: string;
  region?: string;
  country?: string;
  function?: string;
  service?: string;
  notes?: string;
  website?: string;
  structure?: string;
  image?: string;
  canCreateProject?: boolean;
  isInvited?:boolean;
  projectMember?: TProjectMember[];
  attachements: TAttachement[];
  reportProfile: TReportProfile;
  collaboratorProfile: TCollaboratorProfile;
  status:string;
  imageUri?: string;
  company: {
    uuid: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    imageUri: string;
    webSite: string;
    createdAt: string;
    updatedAt: string;
  };
};
