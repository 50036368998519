import { IUser } from '../interfaces';

export type TMessage = {
  content: string;
  user: IUser | string;
  archived: boolean;
  postedAt: string;
};

export type TMessageAttachement = {
  uri: string;
  user: IUser | string;
  archived: boolean;

  contentType: string;
  postedAt: string;
};

export type TChat = {
  id: string;
  archived: boolean;
  messages: TMessage[];
  messageAttachements: TMessageAttachement[];
};

export type IMessage = {
  id?: string;
  content: string;
  user: IUser;
  postedAt: string;
  archived: boolean;
};

export type IMessageAttachement = {
  uri: string;
  user: IUser;
  contentType: string;
  postedAt: string;
  archived: boolean;
};

export type IChat = {
  id?: string;
  archived: boolean;
  messages: IMessage[];
  messageAttachements: IMessageAttachement[];
};
