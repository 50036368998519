import { useEffect, useState } from 'react';
import { auth } from './firebase';
import { Navigate, useLocation } from 'react-router-dom';

export default function ProtectedRoutes({
  children,
}: {
  children: JSX.Element;
}) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });
    return unsubscribe;
  }, []);
  const location = useLocation();
  if (isAuthenticated === null) {
    return children;
  }
  if (!isAuthenticated)
    return <Navigate to="/login" state={{ from: location }} replace />;
  return children;
}
