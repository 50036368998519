// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  api_host: 'https://api-dev.coordate.com/',
  showReactQueryDevtools: true,
  firebase: {
    apiKey:"AIzaSyASAEOOwu-cYkhzLNlCbCxYzFUyTaBk_E0",
    authDomain:"coordate-developement.firebaseapp.com",
    projectId:"coordate-developement",
    storageBucket:"coordate-developement.appspot.com",
    messagingSenderId:"364808874639",
    appId:"1:364808874639:web:b03cddbbd858f07b7b9efa",
    measurementId:"G-Y10G0ZKHHW"
  }
};
