import { array, boolean, object, string } from 'yup';

export const createTodoSchema = object({
  title: string().nullable(),
  rememberUserDate:string().nullable(),
  description: string().nullable(),
  isDone: boolean().required(),
  checklist: array(
    object({
      content: string(),
      isChecked: boolean(),
    })
  ),
});

export const updateTodoDto = object({
  title: string().nullable(),
  description: string().nullable(),
  isDone: boolean(),
  checklist: array(
    object({
      content: string(),
      isChecked: boolean(),
    })
  ),
});
