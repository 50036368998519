import { Route, Routes } from 'react-router-dom';
import { AuthContext } from './global.context';
import { useAuth } from '../core';

import { nonAuthenticatedRoutes } from './routes/non-authenticated.routes';
import ProtectedRoutes from '../lib/protected-routes';
import Dashboard from './pages';
import { auth } from '../lib/firebase';
import { Events } from '../core/hooks/events.hook';
import React from 'react';
import { withTranslation } from 'react-i18next';
import 'gantt-task-react/dist/index.css';

export function App() {
  const { currentUser } = useAuth();
  const { notifications } = Events();
  React.useEffect(() => {
    document.title = 'Coordate Solutions';
  }, []);

  return (
    <AuthContext.Provider value={auth.currentUser}>
      <Routes>
        {nonAuthenticatedRoutes()}
        <Route
          path="*"
          element={
            <ProtectedRoutes>
              <Dashboard notifications={notifications} />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </AuthContext.Provider>
  );
}

export default withTranslation()(App);
