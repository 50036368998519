import { IUser } from '../interfaces';

export type TUser = {
  uuid: string;
  externalId: string;
  email: string;
  fullName: string;
  lastName: string;
} & (
  | {
      role: 'Admin';
    }
  | {
      role: 'Member';
      phoneMobile: string;
      phoneOffice: string;
      faxNumber: string;
      postalCode: string;
      city: string;
      region: string;
      country: string;
      function: string;
      company: string;
      service: string;
      notes: string;
      website: string;
      structure: string;
    }
);

export type TProfile = {
  [Property in keyof IUser]?: IUser[Property];
};
