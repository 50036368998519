import { IUser } from '../interfaces';

export type TCollaboratorProfile = {
  id?: string;
  phone: string;
  establishment: string;
  fullName: string;
  lastName: string;
  email: string;
  image: string;
  user: IUser | string;
};
