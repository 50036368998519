import { EWorkspaceType } from '../enums';
import { IProjectMember, IUser } from '../interfaces';
import { TAttachement } from './attachement.type';
import { TProjectMember } from './project-member.type';
import { TProject } from './project.type';

export type TWorkspace = {
  uuid?: string;
  name: string;
  color: string;
  type: EWorkspaceType | string;
  project?: TProject | string;
  projectMembers?: TProjectMember[];
  progress: number;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  finishDate: string;
  attachements?: TAttachement[];
  nonValidatedtasksCount: number;
  collaborators?:ICollaborator[];
};
export type ICompany = {
  uuid: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  imageUri: string;
  webSite: string;
  createdAt: string;
  updatedAt: string;
};

export type ICollaborator = {
  uuid: string;
  fullName: string;
  imageUri: string;
  phone: string;
  company: ICompany;
};
export type IWorkspace = {
  uuid?: string;
  name: string;
  color: string;
  type: EWorkspaceType | string;
  project?: TProject | string;
  projectMembers?: IProjectMember[];
  progress: number;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  finishDate: string;
  attachements?: TAttachement[];
  nonValidatedtasksCount: number;
  collaborators?: ICollaborator[];
};
