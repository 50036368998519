import { authenticatedRoutes } from '../../../app/routes/authenticated.routes';
import { Route, Routes } from 'react-router-dom';
export default function Main() {
  return (
    <div className="  rounded-md ">
      <Routes>
        {authenticatedRoutes()}
        <Route
          path="*"
          element={
            <div className="flex h-[80vh] w-full items-center justify-center align-middle text-xl font-bold">
              Nous faisons de notre mieux pour
              développer votre rôle dans le projet.
            </div>
          }
        />
      </Routes>
    </div>
  );
}
