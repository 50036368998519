import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {environment} from "../environments/environment";
const firebaseConfig = {
  apiKey: `${environment.firebase.apiKey}`,
  authDomain: `${environment.firebase.authDomain}`,
  projectId: `${environment.firebase.projectId}`,
  storageBucket: `${environment.firebase.storageBucket}`,
  messagingSenderId: `${environment.firebase.messagingSenderId}`,
  appId: `${environment.firebase.appId}`,
  measurementId: `${environment.firebase.measurementId}`
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
