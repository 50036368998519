import { boolean, mixed, number, object, string } from 'yup';
import { EAttachementType, EProjectMemberRole } from '../enums';

export const createAttachementSchema = object({
  folderName: string(),
  remarkToColaborator:string().nullable().notRequired(),
  isValidateByManager:boolean().notRequired(),
  createdBy:mixed<string>()
  .oneOf([ ...Object.values(EProjectMemberRole)]).notRequired().nullable(),
  type: mixed<string>()
    .oneOf(['ALL', ...Object.values(EAttachementType)])
    .required('required'),
  uri: string().required(),
  contentType: string().required(),
  size: number(),
});

export const updateAttachementSchema = object({
  uri: string(),
  contentType: string(),
  size: number(),
  folderName: string(),
});

export const createAttachementsFolderSchema = object({
  name: string().required(),
});

export const updateAttachementsFolderSchema = object({
  name: string(),
});

export const attachementProperties = Object.keys(
  createAttachementSchema.fields
);
