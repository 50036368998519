import React from 'react';
import { Route } from 'react-router-dom';
import { IRoute } from './routes.type';

const LandingPage = React.lazy(() => import('../pages/landing-page'));
const PrivacyPolicy = React.lazy(() => import('../pages/privacy-policy/privacy-policy'));
const ContactUs = React.lazy(() => import('../pages/contact-us'));
const AboutUs = React.lazy(() => import('../pages/about-us'));
const Login = React.lazy(() => import('../pages/auth/loginPage'));
const Register = React.lazy(() => import('../pages/auth/signUpPage'));
const ResetPassword = React.lazy(
  () => import('../pages/auth/resetPasswordPage')
);
const ForgotPassword = React.lazy(
  () => import('../pages/auth/forgetPasswordPage')
);

const routes: IRoute[] = [
  {
    path: '/',
    component: LandingPage,
    name: 'Landing Page',
  },
  {
    path: '/contact-us',
    component: ContactUs,
    name: 'Contact Us',
  },
  {
    path: '/about-us',
    component: AboutUs,
    name: 'About Us',
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    name: 'Login',
  },
  {
    path: '/register',
    component: Register,
    name: 'Sign Up',
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'Forgot Password',
  },
];

export const nonAuthenticatedRoutes = () => {
  return routes.map((route: IRoute, i) => (
    <Route key={i.toString()} path={route.path} element={<route.component />} />
  ));
};
