import {
  collection,
  doc,
  updateDoc,
  query,
  DocumentChange,
  DocumentData,
} from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/toast';
import { ESearchResources, TEvent } from '@coordate/shared';
import { useLocation } from 'react-router-dom';

export const Events = () => {
  const toast = useToast();
  const { pathname } = useLocation();

  const [events] = useCollection(
    query(collection(db, `users/${auth.currentUser?.uid}/events`))
  );

  const [notifications,setNotifications] = useState<any[]>([])
  useEffect(() => {
    const taskId = pathname.includes('/tasks/') ? pathname.split('/tasks/')[1]:null
    // console.log({ values: events?.docChanges() });
    const _notifications = [...notifications]
    events?.docChanges().forEach((notification,index) => {

      const id = notification.doc.id;
      const data = notification.doc.data() as TEvent;
      const isNewNotification = notifications.find(_notification=>_notification.id === notification.doc.id) ? false:true

      if(isNewNotification) {
        console.log({index})
        _notifications.push(notification.doc)
      }

      if (!data.web_displayed && !(data.path.resourceType === ESearchResources.MESSAGE &&  taskId === data.path.task)) {
        toast({
          title: data.content || '',
          status: 'info',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        });
        const ref = doc(db, `users/${auth.currentUser?.uid}/events/${id}`);
        updateDoc(ref, { web_displayed: true });
      }
    });

    setNotifications(_notifications)
  }, [events]);

  useEffect(()=>{
    // console.log({notifications:notifications.length})
  },[notifications])
  return { notifications };
};
