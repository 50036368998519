import { EWorkspaceType } from '../enums';
import { IProjectMember } from '../interfaces';
import { TAttachement } from './attachement.type';
import { TProjectMember } from './project-member.type';
import { TProject } from './project.type';

export type TWorkspace = {
  uuid?: string;
  name: string;
  color: string;
  type: EWorkspaceType | string;
  project?: TProject | string;
  projectMembers?: TProjectMember[];
  progress: number;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  finishDate: string;
  attachements?: TAttachement[];
  nonValidatedtasksCount: number;
};

export type IWorkspace = {
  uuid?: string;
  name: string;
  color: string;
  type: EWorkspaceType | string;
  project?: TProject | string;
  projectMembers?: IProjectMember[];
  progress: number;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  finishDate: string;
  attachements?: TAttachement[];
  nonValidatedtasksCount: number;
};
