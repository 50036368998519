import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const translationEN = {
  landingPage: {
    nav: {
      product: 'Product',
      features: 'Features',
      advanced_features: 'Advanced Features',
      download: 'Download',
      faqs: 'FAQs',
    },
    greetings: {
      title: 'Faciliter le suivi de vos projets avec une solution conçue !',
      description:
        'Vos équipes peuvent collecter rapidement des photos, observations, diagnostics, et avancements sur le chantier. Chaque donnée est instantanément centralisée, permettant la génération de rapports complets en un seul clic.',
      getStartedButton: 'Get Started',
    },
    features: {
      title: 'Every feature you need to win. Try it for yourself.',
      description:
        'Pocket was built for investors like you who play by their own rules and aren’t going to let SEC regulations get in the way of their dreams. If other investing tools are afraid to build it, Pocket has it.',
      card1: {
        title: 'Invite friends for better returns',
        description:
          'For every friend you invite to Pocket, you get insider notifications 5 seconds sooner. And it’s 10 seconds if you invite an insider.',
      },
      card2: {
        title: 'Notifications on stock dips',
        description:
          'Get a push notification every time we find out something that’s going to lower the share price on your holdings so you can sell before the information hits the public markets.',
      },
      card3: {
        title: 'Invest what you want',
        description:
          'We hide your stock purchases behind thousands of anonymous trading accounts, so suspicious activity can never be traced back to you.',
      },
    },
    advancedFeatures: {
      title: 'Now is the time to build your portfolio.',
      description:
        'With typical market returns, you have to start young to secure your future. With Pocket, it’s never too late to build your nest egg.',
      card1: {
        title: 'Efficacité',
        description:
          'Notre application rationalise la gestion des projets, vous permettant de vous concentrer sur l\'essentiel—réaliser le travail.',
      },
      card2: {
        title: 'Collaboration',
        description:
          'Avec le chat, les commentaires et une galerie de projets, votre équipe peut collaborer en temps réel et rester sur la même page.',
      },
      card3: {
        title: 'Organisation',
        description:
          'Les espaces de travail et le partage de tâches facilitent la centralisation de toutes les informations, réduisant ainsi le désordre et la confusion.',
      },
      card4: {
        title: 'Transparence',
        description:
          'Restez informé avec des résumés de projets, vous assurant une vue d\'ensemble claire de vos projets à tout moment.',
      },
      card5: {
        title: 'Convivialité',
        description:
          'Notre application est conçue avec une interface conviviale, la rendant accessible à tous, des chefs de projet aux collaborateurs.',
      },
      card6: {
        title: 'Sécurité',
        description:
          'Nous priorisons la sécurité de vos données. Les informations de votre projet sont sécurisées et accessibles uniquement aux utilisateurs autorisés.',
      },
    },
    download: {
      title: 'Obtenez vos premiers conseils dès aujourd\'hui',
      description:
        'Il vous faudra 30 secondes pour vous inscrire. Téléchargez l\'application et créez un compte dès aujourd\'hui et nous vous enverrons un pourboire qui doublera à coup sûr votre premier investissement.',
    },
    faqs: {
      title: 'Frequently asked questions',
      description:
        'Our whole business depends on our tips being good, so it’s in our best interest that they are. The results of our customers speak for themselves, just trust us.',
      card1: {
        title: 'How do I know the tips are good?',
        description:
          'Whether it’s $1 or $1,000,000, we can put your money to work for you.',
      },
      card2: {
        title: 'Do the people giving you tips realize what they are doing?',
        description:
          'Again I would argue this isn’t really our responsibility. People make their own choices. If they don’t research the consequences that’s on them, not on us.',
      },
      card3: {
        title: 'How did you get this on the App Store?',
        description:
          'Honestly we were surprised too, but eventually we found out that the app reviewer found the app so compelling they approved it just so they could use it themselves.',
      },
      card4: {
        title: 'Isn’t this insider trading?',
        description:
          'Yes exactly. But at scale! Historically you could only make insider trades with knowledge from your direct network. Pocket brings you insider trading tips from people you don’t even know.',
      },
      card5: {
        title: 'Where is Pocket based?',
        description:
          'Let’s just say it’s not somewhere where the SEC is going to find us.',
      },
      card6: {
        title: 'How do I explain the money I withdraw from Pocket to the IRS?',
        description:
          'This feels like one-hundred percent a you problem. Pocket is not responsible in any way for your tax returns.',
      },
      card7: {
        title: 'But isn’t insider trading illegal?',
        description:
          'Here’s the thing: you’re the one doing the insider trading, not us. We’re just giving you the tips and some tools to make trades. We’re not doing anything wrong here.',
      },
      card8: {
        title: 'Is there any age limit to trading on Pocket?',
        description:
          'For our free plan, the age limit is based on the minimum age to trade in your country of residence. Our VIP plan uses advanced transaction anonymization though, so you can use that plan even if you’re 9 years old. Or a dog.',
      },
      card9: {
        title: 'How do I become an insider?',
        description:
          'Contact us with some details about your industry and the type of access you have to apply for an insider account. Once approved, we’ll send you a guide on collecting insider information without being detected at work.',
      },
    },
  },
  sidebar: {
    noProjectFound: 'No Project Selected',
    workspaces: 'Workspaces',
    archive: 'Archive',
    planning: 'Planning',
    documents: 'Documents',
    notes: 'Notes',
    search: 'Search',
  },
  header: {
    noNotifs: 'No notifications found.',
  },
};

const translationFR = {
  landingPage: {
    nav: {
      product: 'Produits',
      features: 'Fonctionnalités',
      advanced_features: 'Fonctionnalités Avancés',
      download: 'Télécharger',
      faqs: 'FAQs',
    },
    greetings: {
      title: 'Invest at the perfect time.',
      description:
        'By leveraging insights from our network of industry insiders, you’ll know exactly when to buy to maximize profit, and exactly when to sell to avoid painful losses.',
      getStartedButton: 'Get Started',
    },
    features: {
      title: 'Every feature you need to win. Try it for yourself.',
      description:
        'Pocket was built for investors like you who play by their own rules and aren’t going to let SEC regulations get in the way of their dreams. If other investing tools are afraid to build it, Pocket has it.',
      card1: {
        title: 'Invite friends for better returns',
        description:
          'For every friend you invite to Pocket, you get insider notifications 5 seconds sooner. And it’s 10 seconds if you invite an insider.',
      },
      card2: {
        title: 'Notifications on stock dips',
        description:
          'Get a push notification every time we find out something that’s going to lower the share price on your holdings so you can sell before the information hits the public markets.',
      },
      card3: {
        title: 'Invest what you want',
        description:
          'We hide your stock purchases behind thousands of anonymous trading accounts, so suspicious activity can never be traced back to you.',
      },
    },
    advancedFeatures: {
      title: 'Now is the time to build your portfolio.',
      description:
        'With typical market returns, you have to start young to secure your future. With Pocket, it’s never too late to build your nest egg.',
      card1: {
        title: 'Invest any amount',
        description:
          'Whether it’s $1 or $1,000,000, we can put your money to work for you.',
      },
      card2: {
        title: 'Build a balanced portfolio',
        description:
          'Invest in different industries to find the most opportunities to win huge.',
      },
      card3: {
        title: 'Trade in real-time',
        description:
          'Get insider tips on big stock moves and act on them within seconds.',
      },
      card4: {
        title: 'Profit from your network',
        description:
          'Invite new insiders to get tips faster and beat even other Pocket users.',
      },
      card5: {
        title: 'Encrypted and anonymized.',
        description:
          'Cutting-edge security technology that even the NSA doesn’t know about keeps you hidden.',
      },
      card6: {
        title: 'Portfolio tracking',
        description:
          'Watch your investments grow exponentially, leaving other investors in the dust.',
      },
      card7: {
        title: 'Trade in real-time',
        description:
          'Get insider tips on big stock moves and act on them within seconds.',
      },
      card8: {
        title: 'Trade in real-time',
        description:
          'Get insider tips on big stock moves and act on them within seconds.',
      },
      card9: {
        title: 'Trade in real-time',
        description:
          'Get insider tips on big stock moves and act on them within seconds.',
      },
    },
    download: {
      title: 'Get your first tips today',
      description:
        'It takes 30 seconds to sign up. Download the app and create an account today and we’ll send you a tip guaranteed to double your first investment.',
    },
    faqs: {
      title: 'Frequently asked questions',
      description:
        'Our whole business depends on our tips being good, so it’s in our best interest that they are. The results of our customers speak for themselves, just trust us.',
      card1: {
        title: 'How do I know the tips are good?',
        description:
          'Whether it’s $1 or $1,000,000, we can put your money to work for you.',
      },
      card2: {
        title: 'Do the people giving you tips realize what they are doing?',
        description:
          'Again I would argue this isn’t really our responsibility. People make their own choices. If they don’t research the consequences that’s on them, not on us.',
      },
      card3: {
        title: 'How did you get this on the App Store?',
        description:
          'Honestly we were surprised too, but eventually we found out that the app reviewer found the app so compelling they approved it just so they could use it themselves.',
      },
      card4: {
        title: 'Isn’t this insider trading?',
        description:
          'Yes exactly. But at scale! Historically you could only make insider trades with knowledge from your direct network. Pocket brings you insider trading tips from people you don’t even know.',
      },
      card5: {
        title: 'Where is Pocket based?',
        description:
          'Let’s just say it’s not somewhere where the SEC is going to find us.',
      },
      card6: {
        title: 'How do I explain the money I withdraw from Pocket to the IRS?',
        description:
          'This feels like one-hundred percent a you problem. Pocket is not responsible in any way for your tax returns.',
      },
      card7: {
        title: 'But isn’t insider trading illegal?',
        description:
          'Here’s the thing: you’re the one doing the insider trading, not us. We’re just giving you the tips and some tools to make trades. We’re not doing anything wrong here.',
      },
      card8: {
        title: 'Is there any age limit to trading on Pocket?',
        description:
          'For our free plan, the age limit is based on the minimum age to trade in your country of residence. Our VIP plan uses advanced transaction anonymization though, so you can use that plan even if you’re 9 years old. Or a dog.',
      },
      card9: {
        title: 'How do I become an insider?',
        description:
          'Contact us with some details about your industry and the type of access you have to apply for an insider account. Once approved, we’ll send you a guide on collecting insider information without being detected at work.',
      },
    },
  },
  sidebar: {
    noProjectFound: 'Pas de projet selectionné',
    workspaces: 'Lots',
    archive: 'Archive',
    planning: 'Planning',
    documents: 'Documents',
    notes: 'Notes',
    search: 'Recherche',
  },
  header: {
    noNotifs: 'Pas de notifications trouver',
  },
};

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};
i18n?.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
