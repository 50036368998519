import React from 'react';
import { Route } from 'react-router-dom';
import { IRoute } from './routes.type';

const Projects = React.lazy(() => import('../pages/projects'));
const ResetPassword = React.lazy(
  () => import('../pages/auth/resetPasswordPage')
);
const DeleteMe = React.lazy(
  () => import('../pages/auth/delete-account')
);
const Reports = React.lazy(() => import('../pages/reports'));
const Members = React.lazy(
  () => import('../pages/members/all-project-members')
);
//// const Members = React.lazy(() => import('../pages/members'));
const MembersList = React.lazy(() => import('../pages/members/members-table'));
const Planning = React.lazy(() => import('../pages/planning'));
const Search = React.lazy(() => import('../pages/search'));
const CreateProject = React.lazy(() => import('../pages/projects/create'));
const Workspaces = React.lazy(() => import('../pages/workspaces'));
const Documents = React.lazy(() => import('../pages/documents'));
const Gallery = React.lazy(() => import('../pages/gallery'));

const Notes = React.lazy(() => import('../pages/notes'));
const NotesList = React.lazy(() => import('../pages/notes/notes-list'));
const FolderAttachements = React.lazy(
  () => import('../pages/documents/folder-attachements')
);
const Tasks = React.lazy(() => import('../pages/tasks'));
const ValidatedTasks = React.lazy(() => import('../pages/tasks/archive'));
const Chat = React.lazy(() => import('../pages/tasks/chat'));
const Attachements = React.lazy(() => import('../pages/tasks/attachements'));
const Todos = React.lazy(() => import('../pages/tasks/todos'));
const Messages = React.lazy(() => import('../pages/messages'));

const routes: IRoute[] = [
  {
    path: '/projects',
    component: Projects,
    name: 'Projects',
  },
  {
    path: '/delete-my-account',
    component: DeleteMe,
    name: 'DeleteAccount',
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'Reset Password',
  },
  {
    path: '/reports',
    component: Reports,
    name: 'Reports',
  },
  {
    path: '/gallery',
    component: Gallery,
    name: 'gallery',
  },
  {
    path: '/workspaces',
    component: Workspaces,
    name: 'workspaces',
  },
  {
    path: '/planning',
    component: Planning,
    name: 'Planning',
  },
  {
    path: '/messages',
    component: Messages,
    name: 'Messages',
  },
  {
    path: '/search',
    component: Search,
    name: 'Search',
  },
  {
    path: '/members',
    component: Members,
    name: 'Members',
  },
  {
    path: '/projects/:projectId/members/:workspaceId/members-list',
    component: MembersList,
    name: 'Members List',
  },
  {
    path: '/projects/create',
    component: CreateProject,
    name: 'Create Project',
  },
  {
    path: '/projects/:projectId/workspaces',
    component: Workspaces,
    name: 'Workspaces',
  },
  {
    path: '/documents',
    component: Documents,
    name: 'Documents',
  },
  {
    path: '/projects/:projectId/notes/:workspaceId/notes-list',
    component: NotesList,
    name: 'Notes',
  },
  {
    path: '/projects/:projectId/notes-folders',
    component: Notes,
    name: 'Notes',
  },
  // {
  //   path: '/projects/:projectId/folders/:folderId/files',
  //   component: FolderAttachements,
  //   name: 'Folder Attachements',
  // },
  {
    path: '/projects/:projectId/workspaces/:workspaceId/tasks',
    component: Tasks,
    name: 'Tasks',
  },
  {
    path: '/projects/:projectId/tasks/archive',
    component: ValidatedTasks,
    name: 'Tasks',
  },
  {
    path: '/projects/:projectId/workspaces/:workspaceId/tasks/:taskId',
    component: Chat,
    name: 'Chat',
  },
  {
    path: '/projects/:projectId/workspaces/:workspaceId/attachements',
    component: Attachements,
    name: 'Attachements',
  },
  {
    path: '/projects/:projectId/workspaces/:workspaceId/todos',
    component: Todos,
    name: 'Todos',
  },
];

export const authenticatedRoutes = () => {
  return routes.map((route: IRoute, i) => (
    <Route key={i.toString()} path={route.path} element={<route.component />} />
  ));
};
