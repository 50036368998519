import { ValidationError } from 'yup';

export type Errors = {
  [path: string]: string | object;
};

export const yupErrorsSerializer = (exception: ValidationError) => {
  const errors: Errors = {};

  exception.inner.map((err) => {
    err.path && (errors[err.path] = err.message);
  });

  return errors;
};
