import React from 'react';
import Main from '../../app/layouts/Main';
import useGetCurrentUser from '../../core/hooks/users/users.hook';
import Sidebar from '../../components/sidebar/sidebar';
import { useLocation } from 'react-router-dom';
import Header from '../../components/header';

export default function Example(props: any) {
  const { data, isLoading } = useGetCurrentUser();
  const { pathname } = useLocation();
  return (
    <div
      className={`min-h-screen ${pathname === '/projects' && 'bg-teal-black'}` }
    >
     {(pathname !== '/projects' && pathname !== '/reset-password' && pathname !== '/delete-my-account') && <Sidebar />}

      <div
        className={`flex min-h-screen flex-1 flex-col bg-gray-100 ${
          pathname !== '/projects' && pathname !== '/reset-password' && pathname !== '/delete-my-account' && !isLoading && data !== undefined && data.status === 'ACTIVE'
            ? 'lg:pl-64'
            : ''
        } ${pathname === '/projects' && 'lg:pl-0'}`}
      >
        {pathname !== '/projects' && pathname !== '/reset-password' && pathname !== '/delete-my-account' && (
          <Header />
        )}
        <main className="flex-1 pb-8 ">
          <Main />
        </main>
      </div>
    </div>
  );
}
