import { ESearchResources } from "../enums"

export  type  TEvent = {
    
    content:string
    web_displayed:boolean;
    mobile_displayed:boolean;
    email_sent:boolean;
    createdAt:string;
    clicked?:boolean;
    path:{
        resourceType:ESearchResources.PDF;
        link:string
        
    } |{
        resourceType:ESearchResources.PROJECT;
        project:string
    } | {
        resourceType:ESearchResources.WORKSPACE;
        project:string
        workspace:string
    } | {
        resourceType:ESearchResources.TASK;
        project:string
        workspace:string
        task:string
    } | {
        resourceType:ESearchResources.MESSAGE;
        project:string
        workspace:string
        task:string
        chat:string
    }
}