import { object, string, mixed, boolean } from 'yup';
import { EUserRoles } from '../enums';
import { IUser } from '../interfaces';
import { regExpressions } from '../regex';

export const createUserSchema = object({
  role: mixed<EUserRoles>().oneOf(Object.values(EUserRoles) as any[]),
  phoneMobile: string()
    .matches(regExpressions.phone, 'Phone number is not valid')
    .max(15, 'to long'),
  phoneOffice: string()
    .matches(regExpressions.phone, 'Phone number is not valid')
    .max(15, 'to long'),
  faxNumber: string()
    .matches(regExpressions.phone, 'Phone number is not valid')
    .max(15, 'to long'),
  postalCode: string().max(20, 'to long'),
  city: string().max(255, 'to long'),
  region: string().max(255, 'to long'),
  country: string().length(3, 'must be 3 characters'),
  function: string().max(255, 'to long'),
  company: string().max(255, 'to long'),
  service: string().max(255, 'to long'),
  notes: string(),
  website: string().max(255, 'to long'),
  structure: string().max(255, 'to long'),
  fullName: string().max(50, 'to long'),
  lastName: string().max(50, 'to long'),
  email: string()
    .required('required')
    .matches(regExpressions.email, 'Email is not valid')
    .max(128, 'to long'),
    externalId: string().required('required').max(128, 'to long'),
  image:string(),
  
});
