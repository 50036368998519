import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/api';
import { IProject, IUser, TProject } from '@coordate/shared';
import { IError } from '../../models/error.model';
import { useToast } from '@chakra-ui/react';

const name = 'project';

export function useCreateProject() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<
    Omit<TProject, 'reports'>,
    unknown,
    Omit<TProject, 'reports'>,
    string[]
  >(
    (values) => {
      return api.post('/api/projects', values).then((res) => res.data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['projects']);
      },
      async onSuccess(data) {
        toast({
          title: `"${data.name}" créé avec succès !`,
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });

        try {
          await api.post(`/api/projects/${data.uuid}/workspaces`, {
            name: 'Manager',
            color: '#10B981',
          });
          try {
            const rootResponse = await api.get(
              `/api/projects/${data.uuid}/attachment-folders/root`
            );
            const rootId = (rootResponse.data as { uuid: string }).uuid;

            await api.post(
              `/api/projects/${data.uuid}/attachment-folders/${rootId}/folders`,
              {
                name: 'Map',
              }
            );
          } catch (error) {
            console.error('Error during Creating Map folder:', error);
          }
        } catch (error) {
          console.error('Error during Creating Manager workspace:', error);
        }
      },
      onError(error, variables, context) {
        const e = error as IError;
        toast({
          title: `"Une erreur s'est produite lors de la création de votre projet !`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      },
    }
  );
}
interface IApiResponse {
  data: IProject[];
  elementsCount: number;
  pagesCount: number;
}
export function useFetchProjects(params?: object) {
  const queryClient = useQueryClient();

  return useQuery<IApiResponse, Error>(
    ['projects'], // Updated query key
    async (): Promise<IApiResponse> => {
      const res = await api.get('api/projects?size=50');
      return res.data as IApiResponse; // Cast to IApiResponse here
    },
    {
      initialData: () => queryClient.getQueryData<IApiResponse>(['projects']), // Use the new query key for initial data
    }
  );
}

export function useGetProject(projectId: string) {
  const queryClient = useQueryClient();
  return useQuery<IProject, Error, IProject, string[]>(
    [name, projectId],
    () =>
      api
        .get(`api/projects/${projectId}`)
        .then((res) => res.data) as Promise<IProject>,
    {
      initialData: () => queryClient.getQueryData([name, projectId]),
      enabled: !!projectId,
    }
  );
}

export const useEditProject = (projectId: string) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation<Partial<TProject>, unknown, Partial<TProject>, string[]>(
    (values) =>
      api.patch(`api/projects/${projectId}`, values).then((res) => {
        console.log('res.data', res.data);
        return res.data;
      }),

    {
      onSettled: () => queryClient.invalidateQueries([name]),
      onSuccess(data) {
        toast({
          title: `"${data.name}" éditer avec succée  !`,
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      },
      onError() {
        toast({
          title: `Une erreur s'est produite lors de l'édition de votre projet !`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      },
    }
  );
};

export function useDeleteProject() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<TProject, unknown, string, string[]>(
    (projectUuid) =>
      api.delete(`/api/projects/${projectUuid}`).then((res) => res.data),

    {
      onSettled: () => queryClient.invalidateQueries([name]),
      onSuccess(data, variables) {
        localStorage.removeItem('projectId');
        localStorage.removeItem('workspaceId');
        toast({
          title: `Le projet est supprimé avec succès  !`,
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
        window.location.assign(`/projects`);
      },
      onError(error) {
        const e = error as IError;
        toast({
          title: `Une erreur s'est produite lors de la suppression de votre projet !`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      },
    }
  );
}
export function useGeTProjectMembers(projectId: string) {
  const queryClient = useQueryClient();
  return useQuery<unknown, Error, IUser[], string[]>(
    [name, projectId, 'members'],
    () =>
      api
        .get(`api/projects/${projectId}/members`)
        .then((res) => res.data) as Promise<IUser[]>,
    {
      initialData: () =>
        queryClient.getQueryData<IUser[]>([name, projectId, 'members']),
      enabled: !!projectId,
    }
  );
}
