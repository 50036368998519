import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './app/app';
import { environment } from './environments/environment';
import 'react-toastify/dist/ReactToastify.css';
import { ChakraProvider } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';

const { ToastContainer, toast } = createStandaloneToast();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <React.Suspense
          fallback={
            <div className="flex h-screen w-full items-center justify-center align-middle">
              <img
                src="/assets/logo-details.png"
                className="w-48 rounded"
                alt="La brique Bleu Logo"
              />
            </div>
          }
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ToastContainer />
        </React.Suspense>
      </ChakraProvider>
      {environment.showReactQueryDevtools && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </StrictMode>
);
