import React, { Fragment } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const projectName = localStorage.getItem('projectName');
  const { pathname } = useLocation();

  return (
    <div className=" sticky mt-10  ml-10">
      <Link
        to="/projects"
        aria-current={pathname === `/projects` ? 'page' : undefined}
      >
        <span className="text-xl">Mes Project / </span>
      </Link>

      <span className="text-xl font-bold">{projectName}</span>
    </div>
  );
}
