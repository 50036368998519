import { EProjectMemberRole, EUserStatus } from '../enums';
import { IUser } from '../interfaces';
import { TProject } from './project.type';
import { TWorkspace } from './workspace.type';

export type TProjectMember = {
  id: string;
  role: EProjectMemberRole | string;
  status: EUserStatus | string;
  invitedAt: string;
  invitedBy: IUser | string;
  project: TProject | string;
  user: IUser | string;
  workspaces: TWorkspace[] ;
};
