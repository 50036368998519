import { object, string, date } from 'yup';
import { regExpressions } from '../regex';
import { parseDateString } from '../utils';

export const createCollaboratorProfileSchema = object({
    fullName: string().notRequired().max(128).nullable(),
    lastName: string().notRequired().nullable(),
    phone: string().notRequired().max(15).nullable(),
    establishment: string().notRequired().nullable(),
  image: string().notRequired().nullable(),
email: string().notRequired().nullable()
    .matches(regExpressions.email, 'Email is not valid')
    .max(255)
    ,
 
});

const baseSchema = createCollaboratorProfileSchema.fields;

export const updateCollaboratorProfileSchema = object({
  ...baseSchema,
  
});
