import { EProjectMemberRole, EUserStatus } from '../enums';
import { TProject, TWorkspace } from '../types';
import { IUser } from './user.interface';
interface Member {
  id: string;
  role: EProjectMemberRole | string;
  status: EUserStatus | string;
  invitedAt: string;
  invitedBy: IUser;
  project: TProject;
  user: IUser;
  workspaces: TWorkspace[];
}
export interface IProject {
  uuid: string;
  name: string;
  description: string;
  address: string;
  postalCode: string;
  city: string;
  region: string;
  country: string;
  phoneOffice: string;
  phoneMobile: string;
  faxNumber: string;
  email: string;
  startDate: string;
  receptionObjective: string;
  clientName: string;
  projectMembers?: Member[];
  image: string;
  imageUri: string;
  projectOrder: number;
  currentUserAuthorizations: UserAuthorization[];
}
export interface UserAuthorization {
  workspaceUuid: string;
  role: string;
  authorizations: Authorization[];
}

export interface Authorization {
  name: string;
  enabled: boolean;
}
