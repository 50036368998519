import React from 'react';
import {
  Search,
  Image,
  FileText,
  Box,
  MessageSquare,
  ClipboardList,
  Calendar,
  Bell,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useGetCurrentUser from '../../core/hooks/users/users.hook';

import Avatar from '../Avatar';

export default function Sidebar() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data: user, isLoading } = useGetCurrentUser();
  return !isLoading && user !== undefined ? (
    <aside className="border-r border-gray-300">
      {/* Static sidebar for desktop */}
      <div className="z-[999] hidden border-r border-gray-200 lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div
          className="flex flex-grow flex-col overflow-y-auto pt-5 pb-4"
          style={{ background: '#E4EBF0' }}
        >
          <div className="mt-2 flex w-full items-center justify-center gap-2 px-3 ">
            <Link
              to="/projects"
              aria-current={pathname === `/projects` ? 'page' : undefined}
            >
              <Avatar
                height={'80px'}
                width={'190px'}
                rounded={'md'}
                name={!isLoading && user !== undefined ? user.fullName : ''}
                url={user?.company.imageUri || ''}
              />
            </Link>
          </div>
          <nav
            className="mt-5 flex flex-1 flex-col overflow-y-auto"
            aria-label="Sidebar"
          >
            <div>
              <Link
                to={`/workspaces`}
                className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                  pathname.includes(`/workspaces`) ? 'bg-white font-bold' : ''
                } `}
                aria-current={pathname === `/workspaces` ? 'page' : undefined}
              >
                <Box className="mr-4 h-6 w-6 " aria-hidden="true" />
                Workspaces
              </Link>
              <Link
                to={`/documents`}
                className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                  pathname.includes(`/documents`) ? 'bg-white font-bold' : ''
                } `}
                aria-current={pathname === `/documents` ? 'page' : undefined}
              >
                <FileText className="mr-4 h-6 w-6 " aria-hidden="true" />
                Documents
              </Link>
              <Link
                to={`/messages`}
                className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                  pathname.includes(`/messages`) ? 'bg-white font-bold' : ''
                } `}
                aria-current={pathname === `/messages` ? 'page' : undefined}
              >
                <MessageSquare className="mr-4 h-6 w-6 " aria-hidden="true" />
                Messages
              </Link>
              <Link
                to={`/gallery`}
                className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                  pathname.includes(`/gallery`) ? 'bg-white font-bold' : ''
                } `}
                aria-current={pathname === `/gallery` ? 'page' : undefined}
              >
                <Image className="mr-4 h-6 w-6 " aria-hidden="true" />
                Galerie
              </Link>
              <Link
                to={`/reports`}
                className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                  pathname.includes(`/reports`) ? 'bg-white font-bold' : ''
                } `}
                aria-current={pathname === `/reports` ? 'page' : undefined}
              >
                <ClipboardList className="mr-4 h-6 w-6 " aria-hidden="true" />
                Rapports
              </Link>
              <Link
                to={`/planing`}
                className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                  pathname.includes(`/planing`) ? 'bg-white font-bold' : ''
                } `}
                aria-current={pathname === `/planing` ? 'page' : undefined}
              >
                <Calendar className="mr-4 h-6 w-6 " aria-hidden="true" />
                Planing
              </Link>
            </div>
            <div className="pt-5">
              <div
                style={{
                  height: '2px',
                  backgroundColor: 'teal',
                  width: '70%',
                  margin: '0 auto',
                }}
              />

              <div className="pt-5">
                <Link
                  to={`/map`}
                  className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                    pathname.includes(`/map`) ? 'bg-white font-bold' : ''
                  } `}
                  aria-current={pathname === `/map` ? 'page' : undefined}
                >
                  <Box className="mr-4 h-6 w-6 " aria-hidden="true" />
                  Map
                </Link>
                <Link
                  to={`/tasks`}
                  className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                    pathname.includes(`/tasks`) ? 'bg-white font-bold' : ''
                  } `}
                  aria-current={pathname === `/tasks` ? 'page' : undefined}
                >
                  <FileText className="mr-4 h-6 w-6 " aria-hidden="true" />
                  Tâches
                </Link>
              </div>
              <div className="mt-10">
                <Link
                  to={`/search`}
                  className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                    pathname.includes(`/search`) ? 'bg-white font-bold' : ''
                  } `}
                >
                  <Search className="mr-4 h-6 w-6 " aria-hidden="true" />
                  {t('sidebar.search')}
                </Link>
                <Link
                  to={`/notification`}
                  className={`group ml-7 mr-7 flex items-center rounded-md p-4 text-lg hover:bg-white hover:font-bold ${
                    pathname.includes(`/notification`)
                      ? 'bg-white font-bold'
                      : ''
                  } `}
                  aria-current={
                    pathname === `/notification` ? 'page' : undefined
                  }
                >
                  <Bell className="mr-4 h-6 w-6 " aria-hidden="true" />
                  Notifications
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </aside>
  ) : null;
}
