import { array, bool, boolean, mixed, number, object, string } from 'yup';
import { EAttachementType, ETaskStatus, EWorkspaceType } from '../enums';
import {
  attachementProperties,
  createAttachementSchema,
} from './attachement.schema';

const baseQuerySchema = {
  q: string(),
  page: number().default(0).required(),
  limit: number().default(10).required(),
};
export const querySchema = object(baseQuerySchema);

export const attachementsQuerySchema = object({
  ...baseQuerySchema,
  folder_name: string(),
  type: mixed<string>().oneOf(['ALL', ...Object.values(EAttachementType)]),
  entity_id: string(),
  order_by: mixed<string>().oneOf(attachementProperties).required('required'),
  isAttachedToReport:boolean()
});

export const tasksQuerySchema = object({
  ...baseQuerySchema,
  isArchived:boolean(),
  isReserver:boolean(),
  status:mixed<ETaskStatus>()
  .oneOf(Object.keys(ETaskStatus) as any[]),
});

export const todosQuerySchema = object({
  ...baseQuerySchema,
  projectMember:string().uuid(),
  
});


export const reportsQuerySchema = object({
  ...baseQuerySchema,
  project:string()
})

export const workspaceQuerySchema = object({
  ...baseQuerySchema,
  type: mixed<EWorkspaceType>()
    .oneOf(Object.keys(EWorkspaceType) as any[]),
})
