import { IUser } from '../interfaces';

export type TReportProfile = {
  id: string;
  address: string;
  fullName: string;
  email: string;
  establishment: string;
  website: string;
  image: string;
  phone: string;
  profileCompany: string;
  user: IUser | string;
};
