import { TReport } from "./report.type";

export type TProject = {
  uuid?: string;
  name: string;
  description: string;
  address: string;
  postalCode: string;
  city: string;
  region: string;
  country: string;
  phoneOffice: string;
  phoneMobile: string;
  faxNumber: string;
  email: string;
  startDate: string;
  receptionObjective: string;
  clientName?: string;
  clientEmail:string;
  reports:TReport[];
  image:string;
  planUri: string;
  imageUri:string;
  UUID?: string;


};
