import { mixed, object, string, addMethod, array, number } from 'yup';
import { EWorkspaceType } from '../enums';
import { regExpressions } from '../regex';
import { createAttachementSchema } from './attachement.schema';

addMethod(object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f: any) => value[f] != null),
  });
});


export const inviteProjectMember = object({
  email: string(),
  userId: string().uuid(),
});


export const createWorkspaceSchema = object({
  name: string().max(50).required(),
  color: string().max(10).required(),
  type: mixed<EWorkspaceType>()
    .oneOf(Object.keys(EWorkspaceType) as any[])
    .required(),
  invite_colaborator:inviteProjectMember,
  attachements:array(createAttachementSchema),
  progress:number(),
  startDate:string(),
  finishDate:string(),
});

export const updateWorkspaceSchema = object({
  name: string().max(50),
  color: string().max(10),
  type: mixed<EWorkspaceType>().oneOf(Object.keys(EWorkspaceType) as any[]),
  progress:number(),
  startDate:string(),
  finishDate:string(),
});
