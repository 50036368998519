import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export default function ProtectedRoutes({
  children,
}: {
  children: JSX.Element;
}) {
  const location = useLocation();

  if (localStorage.getItem('tri') === null)
    return <Navigate to="/login" state={{ from: location }} replace />;
  return children;
}
