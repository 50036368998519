import { ETaskStatus } from '../enums';
import { IUser } from '../interfaces';
import { TWorkspace } from './workspace.type';

export type TTask = {
  uuid?: string;
  number?: number;
  title: string;
  description: string;
  status: ETaskStatus | string;
  startedAt?: string;
  finishedAt?: string;
  endDate: string;
  isHidden: boolean;
  isReserver: boolean;
  originalWorkspace?: TWorkspace | string;
  workspaces?: TWorkspace[] | string[];
  progress: number;
  priority: number;
  locate?: string;
  crNumber?:number;
  createdAt: string;
  updatedAt: string;
  zoomLevel?: number;
  positionX?: number;
  positionY?: number;
  elementsCount: number;
  attachments?: {
    uri: string;
    size: number;
    type: string;
    name: string;
    displaySize?: string;
  }[];
  checklist?: {
    checked: boolean;
    description: string;
  }[];
};

export type ITask = {
  uuid?: string;
  number?: number;
  title: string;
  priority: number;
  description: string;
  status: ETaskStatus | string;
  startedAt?: string;
  finishedAt?: string;
  endDate?: string;
  attachments?: {
    uri: string;
    size: number;
    type: string;
    name: string;
    displaySize?: string;

  }[];
  checklist?: {
    checked: boolean;
    description: string;
  }[];
  taskAssignee: IUser[];
  elementsCount: number;

};
