import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';
import { ILoginCredentials, IRegisterCredentials } from '../models/auth.models';
import { IError } from '../models/error.model';
import { auth } from '../../lib/firebase';
import { api } from '../../lib/api';
import { FirebaseError } from 'firebase/app';
export type CurrentUser =
  | {
      externalId: string;
    }
  | undefined;

export const useAuth = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState<IError[]>([]);
  const [registerErrors, setRegisterErrors] = useState<IError[]>([]);

  React.useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setLoading(true);
      // console.log('user :>> ', user);
      if (user)
        await user
          ?.getIdTokenResult()
          .then((res) => {
            localStorage.setItem('tri', res.token);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('userId', res.claims['id']);
            setLoading(false);
          })
          .catch((e: unknown) => console.error(e));
    });
  }, []);

  const refreshTokenUntilClaimsCreated = () => {
    auth.onIdTokenChanged(async (currentUser) => {
      const tokenResult = await currentUser?.getIdTokenResult();
      console.log({ tokenResult });
      if (!tokenResult?.claims['id']) {
        currentUser?.getIdTokenResult(true);
      } else {
        localStorage.setItem('tri', tokenResult.token);
        localStorage.setItem('userId', tokenResult?.claims['id']);
        localStorage.setItem('email', currentUser?.email || '');
        navigate('/projects', { replace: true });
      }
    });
  };

  const login = async (loginCredentials: ILoginCredentials) => {
    setLoading(true);
    await signInWithEmailAndPassword(
      auth,
      loginCredentials.email,
      loginCredentials.password
    )
      .then((res) => {
        console.log('res', res);
        refreshTokenUntilClaimsCreated();
      })
      .then(() => navigate('/projects', { replace: true }))
      .catch((error: unknown) => {
        const e = error as FirebaseError;
        console.log(error);
        loginErrorsHandler(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loginErrorsHandler = (errorMessage: any) => {
    if (loginErrors.length > 0) setLoginErrors([]);

    console.log({ errorMessage });
    let handledError: IError = {
      error: '',
      field: '',
      remote: false,
    };
    switch (errorMessage.code) {
      case 'auth/user-not-found':
        handledError = {
          error: 'L\'utilisateur n\'existe pas',
          field: 'email',
          remote: true,
        };

        break;

      case 'auth/wrong-password':
        handledError = {
          error: 'Mot de passe incorrect ',
          field: 'password',
          remote: true,
        };
        break;

      case 'auth/invalid-email':
        handledError = {
          error: 'Format d\'e-mail non valide, veuillez vérifier l\'e-mail que vous avez saisi',
          field: 'email',
          remote: true,
        };
        break;

      default:
        handledError = {
          error: 'Server Error , please try again or contact admin',
          field: 'server',
          remote: true,
        };
    }

    setLoginErrors((prev) => [...prev, handledError]);
  };

  const register = async (registerCredentials: IRegisterCredentials) => {
    setLoading(true);
    try {
      // Create user with email and password in Firebase
      const res = await createUserWithEmailAndPassword(
        auth,
        registerCredentials.email,
        registerCredentials.password
      );

      setCurrentUser(res.user); // Update the current user state
      const { email } = res.user;
      const idToken = await res.user.getIdToken(); // Get the token from the newly created user

      if (idToken && email) {
        localStorage.setItem('tri', idToken);
        localStorage.setItem('email', email);
      }

      // Post the user details to your API
      await api.post('api/users/register', {
        email,
        externalId: res.user.uid,
        fullName: registerCredentials.fullName,
      });

      localStorage.setItem('user', JSON.stringify(res.user));

      // Call the function that handles token refresh and claims creation
      await refreshTokenUntilClaimsCreated();

      // Navigate to projects after the entire process is completed
      navigate('/projects', { replace: true });
    } catch (error) {
      const e = error as FirebaseError;
      console.log(e);
      registerErrorsHandler(e);
    } finally {
      setLoading(false);
    }
  };


  const registerErrorsHandler = (errorCode: any) => {
    console.log({ errorCode });

    if (registerErrors.length > 0) setRegisterErrors([]);

    let handledError: IError = {
      error: '',
      field: '',
      remote: false,
    };
    switch (errorCode.code) {
      case 'auth/user-not-found':
        handledError = {
          error: 'User does not exist',
          field: 'email',
          remote: true,
        };

        break;

      case 'auth/wrong-password':
        handledError = {
          error: 'Wrong password ',
          field: 'password',
          remote: true,
        };
        break;

      case 'auth/invalid-email':
        handledError = {
          error: 'Invalid Email format , please verify the email you entered ',
          field: 'email',
          remote: true,
        };
        break;

      case 'auth/weak-password':
        handledError = {
          error: 'Password is weak ',
          field: 'password',
          remote: true,
        };
        break;

      case 'auth/email-already-in-use':
        handledError = {
          error: 'Email is already is use ',
          field: 'email',
          remote: true,
        };
        break;

      default:
        handledError = {
          error: 'Server Error , please try again or contact admin',
          field: 'server',
          remote: true,
        };
    }

    setRegisterErrors((prev) => [...prev, handledError]);
  };

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  async function logout() {
    setLoading(true);
    await signOut(auth)
      .then(() => {
        // Clear all local storage data related to authentication
        localStorage.removeItem('tri');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('email');

        setCurrentUser(null); // Reset current user state
        console.log('Logged out successfully');
        navigate('/login', { replace: true }); // Redirect to login after logout
      })
      .catch((e: unknown) => {
        console.error('Logout error:', e);
      })
      .finally(() => {
        setLoading(false); // Stop loading spinner if any
      });
  }

  return {
    currentUser,
    login,
    register,
    loginErrors,
    registerErrors,
    loading,
    setRegisterErrors,
    setLoginErrors,
    logout,
  };
};
