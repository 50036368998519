import { EReportCollaboratorStatus } from '../enums';
import { IUser } from '../interfaces';
import { TProject } from './project.type';
import { TUser } from './user.type';
import { TWorkspace } from './workspace.type';

export type TReportCollaborator = {
  user: IUser | string;
  status: EReportCollaboratorStatus | string;
};

export type TReportWorkspace = {
  workspace: TWorkspace | string;
  progress: number;
};

export type TEnterpriseDetails = {
  name: string;
  address: string;
  phone: string;
  logo: string;
  email: string;
};

export type TReport = {
  id: string;
  uuid: string;
  number: number;
  crNumber: number;
  pdfUrl: string;
  name: string;
  title:string;
  isReserver: boolean;
  enterpriseDetails: TEnterpriseDetails;
  reportUrl:string;
};
