import { object, string, date } from 'yup';
import { regExpressions } from '../regex';
import { parseDateString } from '../utils';

export const createProjectSchema = object({
  name: string().required().max(128),
  description: string(),
  address: string().max(255).nullable(),
  postalCode: string().max(20).nullable(),
  city: string().max(255).nullable(),
  region: string().max(255).nullable(),
  country: string().max(3).nullable(),
  phoneOffice: string().max(15).nullable(),
  phoneMobile: string().max(15).nullable(),
  faxNumber: string().max(15).nullable(),
  email: string()
    .matches(regExpressions.email, 'Email is not valid')
    .max(255)
    .required(),
  startDate: date().transform(parseDateString).nullable(),
  receptionObjective: string().required(),
  clientName: string().nullable().max(255),
  clientEmail: string().nullable().max(255),
});

const baseSchema = createProjectSchema.fields;

export const updateProjectSchema = object({
  ...baseSchema,
  name: baseSchema.name.notRequired(),
  clientName: baseSchema.clientName.notRequired(),
  phoneMobile: baseSchema.phoneMobile.notRequired(),
});
