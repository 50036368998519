import { object, string, date } from 'yup';
import { regExpressions } from '../regex';
import { parseDateString } from '../utils';

export const createReportProfileSchema = object({
    fullName: string().notRequired().max(128).nullable(),
    establishment: string().notRequired().nullable(),
  website: string().notRequired().nullable(),
  phone: string().notRequired().nullable(),
  profileCompany: string().notRequired().nullable(),

  image: string().notRequired().nullable(),
email: string().notRequired().nullable()
    .matches(regExpressions.email, 'Email is not valid')
    .max(255)
    ,
 
});

const baseSchema = createReportProfileSchema.fields;

export const updateReportProfileSchema = object({
  ...baseSchema,
  
});
