import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/api';
import { IUser, TProfile } from '@coordate/shared';
import { useToast } from '@chakra-ui/react';
const name = 'users';

export function useFetchUsers(params?: object) {
  const queryClient = useQueryClient();

  return useQuery<unknown, Error, IUser[], string[]>(
    ['users'], 
    () =>
      api
        .get(`/api/users`, params) 
        .then((res) => res.data) as Promise<IUser[]>,
    {
      initialData: () => queryClient.getQueryData<IUser[]>(['users']), 
    }
  );
}
export default function useGetCurrentUser() {
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('tri');
  const queryClient = useQueryClient();

  return useQuery<unknown, Error, IUser, string[]>(
    ['users', userId || ' '],
    () =>
      api.get(`api/users/me`).then((res) => res.data) as Promise<IUser>,
    {
      initialData: () => queryClient.getQueryData(['users', userId || ' ']),
      enabled: !!userId && !!token,
    }
  );
}

export const useEditCurrentUser = () => {
  const userId = localStorage.getItem('userId');
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation<TProfile, unknown, TProfile, string[]>(
    (values) =>
      api.patch(`${name}/${userId}`, values).then((res) => {
        console.log('res.data', res.data);
        return res.data;
      }),

    {
      onSettled: () => queryClient.invalidateQueries([name, userId || ' ']),
      onSuccess(data, variables, context) {
        toast({
          title: `"${
            variables.fullName + ' ' + variables.lastName
          }" est édité avec succées`,
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      },
      onError(error, variables, context) {
        toast({
          title: `Une erreur s'est produite lors de l'édition de votre profile !`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
      },
    }
  );
};
export  function useDeleteCurrentUser() {
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('tri');
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      api.delete(`/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users', userId || ' ']);
        localStorage.clear();
        window.location.href = '/';
      },
      onError: (error) => {
        console.error('Error deleting account:', error);
        alert('Une erreur est survenue. Veuillez réessayer.');
      },
    }
  );
}