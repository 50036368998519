import { array, boolean, mixed, number, object, string } from 'yup';
import { ETaskStatus } from '../enums';
import { regExpressions } from '../regex';
import { createAttachementSchema } from './attachement.schema';

export const createTaskSchema = object({
  title: string().max(255).required(),
  description: string(),
  locate: string().nullable().notRequired(),
  finishedAt: string().nullable().notRequired(),
  startedAt: string().nullable().notRequired(),
  status: mixed<ETaskStatus>()
    .oneOf(Object.keys(ETaskStatus) as any[])
    .required(),
  attachements: array(createAttachementSchema),
  progress: number(),
});

export const updateTaskSchema = object({
  title: string().max(255),
  description: string(),
  status: mixed<ETaskStatus>().oneOf(Object.keys(ETaskStatus) as any[]),
  progress: number(),
});

export const transferTaskToWorkspacesSchema = object({
  workspaceIds: array(string()),
});

export const assigneTaskToProjectMemberSchema = object({
  canUpdateTask: boolean().required(),
  userId: string().uuid(),
  email: string(),
});
