import React from 'react';
import useImageExists from '../../core/hooks/image-exists.hook';

export default function Avatar({
  url = '',
  name = '',
  className = '',
  width = '8rem',
  height = '5rem',
  rounded = '',
}) {
  const isExists = useImageExists(url);
  // console.log({ url });
  const [letters, setLetters] = React.useState('');
  React.useEffect(() => {
    if (name) { // Check if name is not null or undefined
      const [fullName, lastName] = name.split(' ');
      if (lastName) {
        setLetters(fullName[0] + lastName[0]);
      } else {
        setLetters(fullName[0]);
      }
    } else {
      setLetters(''); // Or handle the case where name is null or empty
    }
  }, [name]);

  return isExists ? (
    <img
      src={url}
      alt={name}
      className={`${className}  inline-block h-6 w-6 cursor-pointer shadow-lg rounded-${rounded} object-cover  `}
      style={{ width, height }}
      referrerPolicy="no-referrer"
    />
  ) : (
    <div className="p-1">
      <div
        className={`${className} flex h-8 w-8 cursor-pointer items-center justify-center rounded-${rounded} bg-gray-200 p-3 text-xs font-semibold uppercase text-gray-700 shadow-lg shadow-gray-200 ring-2 ring-white `}
        title={name}
        style={{ width, height }}
      >
        <span>{letters}</span>
      </div>
    </div>
  );
}
