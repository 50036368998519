import { object, string, date, mixed } from 'yup';
import { regExpressions } from '../regex';
import { parseDateString } from '../utils';
import { EProjectMemberRole, EUserStatus } from '../enums';

export const createProjectMemberSchema = object({
  role: mixed<EProjectMemberRole>()
    .oneOf(Object.values(EProjectMemberRole) as any[])
    .required('required'),
  status: mixed<EUserStatus>()
    .oneOf(Object.values(EUserStatus) as any[])
    .required('required'),
  project: string().required(),
  user: string().uuid().required(),
});

export const updateProjectMemberSchema = object({
  role: mixed<EProjectMemberRole>().oneOf(
    Object.values(EProjectMemberRole) as any[]
  ),
  status: mixed<EUserStatus>().oneOf(Object.values(EUserStatus) as any[]),
});

export const haveProjectWorkspaceRoleSchema = object({
  role: mixed<EProjectMemberRole>().oneOf(
    Object.values(EProjectMemberRole) as any[]
  ),
});
