import { boolean, number, object, string } from 'yup';

export const createTaskConversationSchema = object({
  task: string().uuid().required(),
  // message: string(),
  // messageAttachement: string(),
});

export const updateTaskConversationSchema = object({
  archived: boolean(),
});

export const sendMessageSchema = object({
  content: string().required(),
});

export const sendMessageAttachementSchema = object({
  uri: string().required(),
  contentType: string().required(),
});

export const chatQuerySchema = object({
  q: string(),
  page: number().default(0).required(),
  limit: number().default(10).required(),
  archived: boolean(),
});

export const updateMessageSchema = object({
  archived: boolean(),
});

export const updateMessageAttachementSchema = object({
  archived: boolean(),
});
