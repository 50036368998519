export type TTodo = {
  id: string;
  title: string;
  isDone: boolean;
  description: string;
  rememberUserDate?:string;
  addedAt?: string;
  updatedAt?:string;
  checklist: TChecklistItem[];
};

export type TChecklistItem = {
  content: string;
  isChecked: boolean;
  parentIndex?: number;
};

export type TCreateTodo = {
  title: string;
  isDone: boolean;
  description: string;
  checklist: {
    content: string;
    isChecked: boolean;
  }[];
};

export type TUpdateTodo = {
  title?: string;
  isDone?: boolean;
  description?: string;
  checklist?: {
    content: string;
    isChecked: boolean;
  }[];
};
