import { EUserInvitationType } from '../enums';
import { TTask } from './task.type';
import { TUser } from './user.type';
import { TWorkspace } from './workspace.type';


export type TUserInvitation = {
    invitedBy:TUser | string;
    projectId?:string;
    
} & (
    {
        resourceType:EUserInvitationType.TASK,
        resource:TTask | string
    }
    |
    {
        resourceType:EUserInvitationType.WORKSPACE,
        resource:TWorkspace | string
    }
)