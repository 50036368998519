import {array, boolean, mixed, number, object, string} from 'yup'
import { EReportCollaboratorStatus } from '../enums';
import { regExpressions } from '../regex';


export const reportWizardStepOneSchema = object({
    name:string().nullable(),
    email: string().
    nullable()
   ,
    address:string().nullable(),
    phone:string().nullable(),
    logo:string().nullable(),
})

export const updateReportWizardStepOneSchema = object({
    name:string(),
    email: string()
    .matches(regExpressions.email, 'Email is not valid')
    .max(255)
    ,
    address:string(),
    phone:string(),
    logo:string(),
})

export const createReportCollaboratorStatusSchema = object({
    user:object({id:string().uuid().required()}),
    status:mixed<string>()
    .oneOf(Object.values(EReportCollaboratorStatus)).required(),
    isConvoquer:boolean().required()
})


export const createReportWorkspaceProgress = object({
    workspace:object({id:string().required()}),
    progress:number().min(-1).required()
})


export const createReportSchema = object({
    enterpriseDetails:reportWizardStepOneSchema.required(),
    collaborators:array(createReportCollaboratorStatusSchema),
    workspaces:array(createReportWorkspaceProgress)
})

export const updateReportSchema = object({
    enterpriseDetails:updateReportWizardStepOneSchema,
    collaborators:array(createReportCollaboratorStatusSchema),
    workspaces:array(createReportWorkspaceProgress)
})